import React from 'react';
import {Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardInfo from '../components/CardInfo';

function Card(props) {
    return (
        <Container fluid={true}>
                <Row className="justify-content-center">
                    <Col md={10} sm={12}>


  <div class="row borderStyle">
    
    <div class="box col-xs-12 col-sm-5">
   
    <Link to={props.item.link}><img class= "img-fluid " src={props.item.imgSrc} alt={props.item.imgSrc} /></Link>
   
    </div>
    
    
    <div class="box description col-xs-12 col-sm-7">
   
      <div className= "g-card-info boxAnimate"> <CardInfo title={props.item.title} subTitle={props.item.subTitle}role={props.item.role}link={props.item.link} /> </div>
    
    </div>
    
  </div>

                    {/* <div className="g-card row" >
        <img className="g-card-image col-sm-6" src={props.item.imgSrc} alt={props.item.imgSrc} />
        <div className= "g-card-info col-sm-6"><CardInfo title={props.item.title} subTitle={props.item.subTitle} link={props.item.link} /> </div>
    </div> */}
                    </Col>
                    </Row>
                    </Container>


    );
}
export default Card;