import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';

import style from '../img/StyleGuide.png';
import logo from '../img/logo.png';
import poster from '../img/Poster.png';
import website from '../img/WebsiteFinal-Huggins.png';
import book1 from '../img/book1.png';
import book2 from '../img/Book2.png';
import book3 from '../img/book3.png';
import bookback from '../img/bookback.png';
import bookcover from '../img/bookCover.png';


function SXSWPage(props) {

    return(
        <div class= "heroSmall">
       

        <Content>
        <Hero title={props.title} />
        <div class="row">
          
         
          <div class="col-xs-12 col-sm-6">
          <div class="skillsBox">
              
            <img class= "img-fluid full-op" src = {bookcover}></img>
            
           
          
          </div>
        </div>
        
        <div class="col-xs-12 col-sm-6">
          <div class="description-sum">
              <ul class="summaryList">
              
                  <li><b>Role:</b></li>
                      <ul>
                          <li>Graphic Designer</li>
                      </ul>
                  
                  <li><b>Project Goals:</b></li>
                      <ul>
                          <li>Redesign an original logo design for South-By-SouthWest Music Festival (SXSW)</li>
                          <li>Create 1-page style guide for redesign concept</li>
                          <li>Create a promotional poster with artist lineup</li>
                          <li>Create 8-page event program with artist headliner profiles</li>
                          <li>Create a website design for a landing site to purchase tickets online and view event program</li>
                      </ul>
                  
                  <li><b>Project Challenges:</b></li>
                      <ul>
                          <li>Recreating a brand identity while representing the same event/festival</li>
                        
                      </ul>
         </ul>

       
          </div>
         

        </div>
     

</div>


<div class = "skillsBox">

<p><b>Creative Brief: </b>(written by: Grayson Mendenhall) A concert promotion
company is planning to host a series of music festivals in the summer of 2022. They have
hired you to design the visual identity, a website, and a digital publication for one of the
two festivals they are planning. You will be entirely responsible for the
visual identity and visual language of the festival. Your deliverables should all share that
same visual style. You should research each festival’s history so you can take that into
account when designing the visuals. </p>
       
   
       <p><b>Skills Used:</b>
                       <ul>
                           <li>Branding</li>
                           <li>Magazine Design</li>
                           <li>Website Design</li>
                           <li><b>Logo Redesign</b></li>
                           <li><b>Adobe Creative Suite Software</b></li> 

                       </ul></p>
      
       </div>
       <h4 class= "sub-heading">New Logo</h4>
       <p>The new logo design takes inspiration from the previous SXSW arrow symbol that points in the SouthWest direction. I changed the logo from a bold sans-serif font to a thin serif.</p>
        <div class="box"><img class= "img-fluid full-op" src = {logo}></img></div>

        <h4 class= "sub-heading">Redesign Concept</h4>
      <p>My concept for the SXSW redesign is inspired by fluid soundwaves. I took inspiration from tech vintage grainy gradients to create a design that thematically represented music and the festival but reimagined SXSW previous style.  </p>

       <div class="row">
 
    
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="boxBlock">
  
      <img class= "img-fluid full-op" src = {style}></img>
      <div class="figcap"><p>Style Guide</p></div>

     
      <img class= "img-fluid full-op" src = {bookcover}></img>
      <div class="figcap"><p>Program Front Cover</p></div>
    
      <img class= "img-fluid full-op" src = {bookback}></img>
      <div class="figcap"><p>Program Back Cover</p></div>

      </div>
    </div>
    
  

    <div class="col-xs-12 col-sm-6 col-md-8">
      <div class="boxBlock">
      
  
    
     
      <img class= "img-fluid full-op" src = {poster}></img>
      <div class="figcap"><p>Promotional Poster</p></div> 

      <h4 class= "sub-heading">Deliverables</h4>
      <p>Above the deliverables include a style guide for the redesign, promotional poster and front and back cover for the event program. The event program pages are featured below along with the website design.  </p>
    
      </div>
      </div>
      </div>


    <h4 class= " add-padding sub-heading">Program Pages</h4>
    <div class="row">
    
    <div class="col-xs-12 ">
  
      <div class="skillsBox">
      <img class= "img-fluid full-op" src = {book1}></img>
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
  
      <div class="skillsBox">
      <img class= "img-fluid full-op" src = {book2}></img>
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
  
      <div class="skillsBox">
      <img class= "img-fluid full-op" src = {book3}></img>
      </div>
    </div>
    </div>


     <h4 class= " sub-heading">Website Design</h4>
     <p>The single-page website design showcases the new brand style while prioritizing the <b>user experience</b> of buying tickets to the festival through the site.</p>
        
      <div class="row">
        
    
    <div class="col-xs-12 ">

      <div class="box">
 
      <img class= "img-fluid full-op" src = {website}></img>
      
      </div>
    </div>
    </div>  
    
    <div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 

        </Content>
        
    </div>
        );
    
    }
    export default SXSWPage;