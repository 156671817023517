import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';

import VRDisplay from '../img/VRPreview.png'
import lenovoThumb from '../img/farmingPreview.png';
import lab from '../img/lab.png';
import story1 from '../img/storyboard2.png';
import story2 from '../img/storyboard3.png';
import story3 from '../img/storyboard0.png';
import story4 from '../img/storyboard1.png';
import video2 from '../img/FoodSecurity.mp4';
function VRPage(props) {

    return(
        <div class= "heroBig">
             <Content>
            <Hero title={props.title} />

            <div class="row">
          
         
          <div class="col-xs-12 col-sm-6">
          <div class="box">
            <img class= "img-fluid full-op" src = {VRDisplay}></img>
           
          
          </div>
        </div>
        
        <div class="col-xs-12 col-sm-6">
          <div class="description-sum">
              <ul class="summaryList">
              
                  <li><b>Role:</b></li>
                      <ul>
                          <li>VR Developer, Content Writer, Producer</li>
                      </ul>
                  
                  <li><b>Project Goals:</b></li>
                      <ul>
                          <li>Work collaboratively to produce a VR demo as a part of Lenovo's global marketing campaign, <a href= "https://news.lenovo.com/pressroom/press-releases/lenovo-launches-vision-of-smarter-technology-for-all-with-global-campaign/"target="_blank"><b><em>Smarter Technology for All </em></b></a></li>
                          <li>To design a VR experience that showcases three real stories of ways scientists and innovators use Lenovo's technology in their respective fields</li>
                        
                      </ul>
                  
                  <li><b>Project Challenges:</b></li>
                      <ul>
                    
                          <li>The March 2020 COVID-19 outbreak caused major changes in the deliverables and project timeline. </li>
                          <li>Learning Unreal Engine remotely</li>
                
                         
                      </ul>
         </ul>
       
          </div>
        </div>

        </div>

        <div class = "skillsBox">
       
       <p><b>Digital Solution: </b> After the March 2020 COVID-19 outbreak, the final deliverables were proof-of-concept VR demos of game concepts related to each story. The production was limited by new travel restrictions and remote communication and collaboration. Overall, the experience was still valuable to me in terms of learning about VR development tools and general game design concepts. </p>
   
       <p><b>Skills Used:</b>
                       <ul>
                        
                             <li>Storyboarding</li>
                             <li>Game Design</li>
                             <li>Multidisciplinary Team Collaboration</li>

                            <li>VR Delivery: Oculus Rift S, Lenovo hardware </li>
                            <li>VR Development: Unreal Engine</li>
                       </ul></p>
      
       </div>

       <h4 class= "sub-heading">Original Project Plan</h4>
        <p>The Lenovo client presented various real stories of scientists and innovators that were using Lenovo's technology in their respective fields. The task was to tell these stories with emerging technology in a way that could be presented at a future conference or showcase. The goal was ultimately to market Lenovo's new slogan and vision: Smarter Technology for All, which emphasized accessibility of the technologies. The class decided to create a VR experience that allowed users to enter three mini games related to each of the three real stories.
 </p>

 <h4 class= "sub-heading">Forming Teams</h4>
 <p>The class formed three teams, each assigned to a real story about amazing uses of Lenovo's technology. Each team was made up of a developer, video producer, designer, and content writer.  </p> 
 <div class="row">
    
    <div class="col-xs-12 col-md-7">
      <div class="boxBlock">
   
      <img class= "img-fluid full-op"  src = {lab}></img>
      <div class="figcap"><p>Collaboration in teams (photo credit Daily TarHeel)</p></div>
      </div>
    </div>
    
    <div class="col-xs-12 col-md-5">
   
      <div class="boxBlock">
      <h4 class= "sub-heading">My Role</h4>
      <p>On my team I was the only developer and was responsible for all of the programming in Unreal Engine. I produced the proof-of-concept at the bottom of the page which was the final deliverable for the class (due to COVID-19 complications). In addition, I was a major contributor to the planning and writing of the experience script and game design concept. I want to give full credit for all of the UI designs to the designer on my team.</p>
     
      
      </div>
    </div> 

    
  </div>


  
   <h4 class= "sub-heading">Story Spotlight: New ways to address food shortage due to climate change </h4>
   <p>The story my team was responsible for was Dr. Vatsavai's work with artificial intelligence to better monitor and manage agricultural practices. According to Dr. Ranga Raju Vatsavai from North Carolina State University, with expected population growth and the increasing impacts of global warming, agricultural practices (specifically water usage) need to be better managed in order to prevent global food shortages in the future. He uses Lenovo's hardware and machine learning to train models to monitor crop health and drive water usage based on data.</p>
  
   

    
    <div class="col-xs-12">
      <div class="boxBlock">
      <div class="skillsBox">
      <a href= "https://www.lenovo.com/us/en/smarter/smart-agriculture-protects-food-security/ncsu-ai-protects-food-supplies/"target="_blank"><img class= "img-fluid full-op"  src = {lenovoThumb}></img></a> </div>
      <div class="figcap"><p>Lenovo's <a href = "https://www.lenovo.com/us/en/smarter/smart-agriculture-protects-food-security/ncsu-ai-protects-food-supplies/"target="_blank"> Blog Post</a> on Dr. Vatsavai (photo credit Lenovo)</p></div>
      </div>
    </div>
    
 

    


<h4 class= "sub-heading">Game Concept</h4>
<p>My team decided to create a simple gamification of machine learning. The game’s goal was to make buzzwords like 'machine learning' and 'artificial intelligence' more accessible and understandable to people that don't have working knowledge of how those technologies work. In the game, the user trains a model in the context of Dr. Vatsavai’s work, identifying which crop samples are healthy, dehydrated or unhealthy. The game is simple, but educational in explaining basic machine learning concepts and relating them to Dr. Vatsavai’s story. </p>

<h4 class= "sub-heading">Scripting & Storyboarding</h4>
<p>The team collaborated to script the dialogue for the experience. This process scheduling interviews with scientists to understand the technologies at play, and storyboarding how telling the story would be most meaningful.  </p>
<div class="row">
    
    <div class="col-xs-12">
 
      <div class="box">
      <img class= "img-fluid full-op" id="chart" src = {story1}></img>
     
      </div>
    </div>
    </div>
    
    <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="skillsBox">
    
      <img class= "img-fluid full-op" id="chart" src = {story3}></img>
      </div>
    </div> 
      <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="skillsBox">
      <img class= "img-fluid full-op" id="chart" src = {story4}></img>
      
      </div>
    </div> 
    </div>

    


<h4 class= "sub-heading">Proof-Of-Concept Headset Demo</h4>
<p>In the following demonstration, I talk through the game component of the VR experience designed by my team and developed by me. The experience is overly simplistic in pursuit of explaining the basics of machine learning and artificial intelligence in the context of Dr. Vatsavai’s work. The polished experience would detail more of Dr. Vatsavai's work specifically and father tell his story and work with Lenovo's hardware. <b>Press PLAY to view.</b></p>


<video controls className="img-fluid full-op skillsBox" width= "100%"  name="Motion Graphic" loading= "lazy" >
  <source type="video/mp4"  src={video2}/>
</video>

<h4 class= "sub-heading">Takeaways</h4>
<p>Obviously, the production of the demo is not what the team expected at the start of the project. Due to COVID-19 complications and time constraints the final deliverable was merely a proof-of-concept of the game component of our story. While this lower production deliverable might seem simplistic, I am very proud of it because it was my first time programming in Unreal Engine and for VR deployment. </p><p>I learned so much throughout the project but especially a lot about adaptability. At the beginning of the pandemic, we had no idea how to manage remote communication and learning a dense software like Unreal without in-person support proved to be challenging. That being said, all of the developers from the class helped each other complete their proof-of-concept demos and members of my team-- writers, video producers and designers--supported me throughout the project. The experience working on a multidisciplinary team was very valuable in learning to communicate with cross-functional partners.</p><p> In hindsight, the task of producing a professional VR experience in the given time frame of 5 months was potentially more than amatuer VR developers and student designers, video producers and writers were capable of. That being said, the experience of trying to do so, even in the midst of a pandemic, was so worthwhile.</p>

<div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 

            </Content>
            </div>
             );

            }
    export default VRPage;
            
 