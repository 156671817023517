import React from 'react';


import Container from 'react-bootstrap/Container';
import Card from '../components/Card';
import Row from 'react-bootstrap/Row';

import CTTP from '../img/00CTTPCard.jpg';
import Persona from '../img/01IncarcerationMemorial.jpg';
import Motion from '../img/02AiCard.jpg';
import Mercuria from '../img/03MercuriaCard.jpg';
import SXSW from '../img/04SXSWCard.jpg';
import PFAS from '../img/05PFASCard.jpg';
import Zine from '../img/06ChairsCard.jpg';
import VR from '../img/VRCard-01.png';


import Linked from '../img/linkedIn.png';



class Carousel extends React.Component{
    
    constructor(props){
        super(props);
        this.state = {
            items: [

                {
                    id: 1,
                    title: 'CASE STUDY: Mobile COVID-19 Testing',
                    subTitle: 'UNC-CH Carolina Together Testing Program',
                    role: 'Role: UX/UI Designer',
                    imgSrc: CTTP,
                    link: "/UX",
                    selected: false,
                    number: "odd"
                },

                
                {
                    id: 2,
                    title: 'CASE STUDY: AI Mask Detecting Kiosks',
                    subTitle: 'Greet Health',
                    role: 'Role: UX/UI Designer',
                    imgSrc: Motion,
                    link: "/motiongraphics",
                    selected: false, 
                    number: "odd"
                },
                {
                    id: 3,
                    title: 'CASE STUDY: Virtual Memorial',
                    subTitle: 'North Carolina Health News',
                    role:'Role: UX Designer',
                    imgSrc: Persona,
                    link: "/personas",
                    selected: false,
                    number: "even"
                },
                {
                    id: 4,
                    title: 'EMERGING TECH: VR Demo- Increasing food security with machine learning',
                    subTitle: 'Lenovo',
                    role: 'Role: Virtual Reality Developer, Content Writer, Producer',
                    imgSrc: VR,
                    link: "/VR",
                    selected: false, 
                    number: "even"
                },
                {
                    id: 5,
                    title: 'DESIGN PROJECT: Branding and Marketing',
                    subTitle: 'Mercuria',
                    role: 'Role: Visual/Graphic Designer',
                    imgSrc: Mercuria,
                    link: "/branding",
                    selected: false, 
                    number: "even"
                },
                {
                    id: 6,
                    title: 'DESIGN PROJECT: Multimedia ReDesign',
                    subTitle: 'South-By-SouthWest Music Festival',
                    role: 'Role: Visual/Graphic Designer',
                    imgSrc: SXSW,
                    link: "/multimedia",
                    selected: false,
                    number: "odd"
                },
                {
                    id: 7,
                    title: 'DESIGN PROJECT: Magazine Design',
                    subTitle: 'Multilingual Zine',
                    role: 'Role: Visual/Graphic Designer',
                    imgSrc: Zine,
                    link: "/zine",
                    selected: false,
                    number: "odd"
                },

                {
                id: 8,
                title: 'DIGITAL STORYTELLING: Audio Story',
                subTitle: 'Investigating PFAS in North Carolina Well-Water',
                role: 'Role: Writer, Reporter, Producer',
                imgSrc: PFAS,
                link: "/PFAS",
                selected: false,
                number: "even"
            },
            // {
            //     id: 7,
            //     title: 'Graphic Design',
            //     subTitle: 'Editorial illustrations',
            //     imgSrc: Honey,
            //     link: "/illustrations",
            //     selected: false
            // },
   




                
            ]
        }
    }

    handleCardClick = (id, card) => {

        let items = [...this.state.items];

        items[id].selected = items[id].selected ? false : true;

        items.forEach(item => {
            if(item.id !== id) {
                item.selected = false;
            }
        });

        this.setState({
            items
        });
    }


    makeItems = (items) => {
        return items.map(item => {
            return <Card item={item} click={(e => this.handleCardClick(item.id, e))} key={item.id} />
        })
    }



    render() {
        return(
            <Container fluid = {true}>
                <Row className = "justify-content-around">
                    {this.makeItems(this.state.items)}
                </Row>
            </Container>

    );
    }

}
export default Carousel;