import React from 'react';
import {Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer() {

    return(
        <footer className="mt-5">
            <Container fluid={true}>
                <Row className="border-top justify-content-space-between p-3">

                <Col className="p-0" md={4} sm={12}>
                        <p class = "bottomMenu">&copy; Copyright 2021 Natalie Huggins </p>
                 </Col>
                 <Col className="p-0" md={2} sm={12}></Col>
                 <Col className="p-0" md={6} sm={12}>
                 <ul class="bottomMenu">
            <li class= "bottomMenuli"><Link to="/" rel="noopener noreferrer">Home</Link></li>
            <li class= "bottomMenuli"><Link to="/about" rel="noopener noreferrer"> About</Link></li>
                <li class= "bottomMenuli"><Link to="/work" rel="noopener noreferrer"> Work</Link></li>
                <li class= "bottomMenuli"><Link to="/contact" rel="noopener noreferrer"> Keep In Touch</Link></li>
         </ul>
                 </Col>
                
               
                
                </Row>
            </Container>
        </footer>
    );

}

export default Footer;