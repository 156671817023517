import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import headshot from '../img/skateboardme.JPG';
import headshot2 from '../img/buttercupme.jpg';
import redsink from '../img/red-sink.JPG';
import mountains from '../img/mountains.JPG';
import truck from '../img/truck.JPG';
import green from '../img/green.jpeg';
import sunset from '../img/sunset.jpeg';

import {Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function AboutPage(props) {

    return(
        <div>
             <Content>
            <Hero title={props.title} />
            
 
         
  
        <div class="row">
        <div class="col-xs-12">
          <div class="boxBlock">
          <img class="img-fluid full-op headshot" src={headshot2} ></img>
          <p class = "description-ap">Hi, it's good to finally meet you :) I'm <b><a class= "description-a" href="https://www.linkedin.com/in/natalie-huggins/"target="_blank">Natalie Huggins. </a></b></p>
    
      <p class= "intro">I'm a UX designer with a background in frontend development. I'm passionate about creating accessible and usable products and experiences that are helpful and centered around user needs! 
     
      I'm eager to learn from others around me and strive to bring enthusiasm to my work everyday. 


  
      </p>
      

          </div>
          </div>

    
       
              {/* <img class="img-fluid full-op" src={headshot2} ></img> */}
              

       
          </div>
 
     
             
<div className="boxBlock noPadding2">
       
            <p> I studied computer science and interactive digital media at UNC-Chapel Hill.
            
            While studying computer science at UNC, I craved more classes that emphasized the design process behind digital experiences. I found these courses in the <a href="http://hussman.unc.edu/" target="_blank" rel="noopener noreferrer">Hussman School of Journalism and Media,</a> and set my sights on pursuing a career in UX design.  </p>

            <p>The past two years I've worked as a UX Design fellow at an emerging tech startup, <a href="https://reeseinnovate.com/" target="_blank" rel="noopener noreferrer">Reese Innovation Lab.</a> There, I've had the opportunity to create digital solutions for real clients and users. View my<Link to="/work" rel="noopener noreferrer"> work</Link> to learn more about my experience.</p>

            <p> As a developer turned designer, I believe my background in programming is helpful to cross-functional team communication. I still love occasionally stepping into a developer role if needed and for fun! In my spare time, I'm learning frontend frameworks like React JS while creating personal projects such as this website. I've grown extremely passionate about designing and programming for digital accessibility and am currently studying WCAG and 2021 ADA Accessibility Standards. </p>
            
            </div>

            <div class="row ">
          
          <div class="col-xs-12 col-sm-3">
            <div class="box noPadding4">
                <img class="img-fluid full-op " src={truck} ></img>
                
             
            </div>
          </div>
         
          
          <div class="col-xs-12 col-sm-3">
            <div class="box noPadding4">
       
             <img class="img-fluid full-op " src={redsink} ></img>
            </div>
          </div>

          <div class="col-xs-12 col-sm-3">
            <div class="box noPadding4">
       
             <img class="img-fluid full-op " src={mountains} ></img>
            </div>
          </div>

          <div class="col-xs-12 col-sm-3">
            <div class="box noPadding4">
       
             <img class="img-fluid full-op " src={headshot} ></img>
            </div>
          </div>

        </div>

        <div className="row">

            <p>I'm also a huge fan of film photography, stand up comedy, and banana bread. Currently obsessed with Tiny Desk, sudoku and the Fruit Bats cover of Today by The Smashing Pumpkins AND--I would love to be friends on Spotify.</p>

 
            </div>

            <iframe src="https://open.spotify.com/embed/track/2M605uHCPSyTuiWhplewX1?utm_source=generator" width="100%" height="80" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>

    
       
            <div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 

 
         
            </Content>
 

     
        </div>
    );

}

export default AboutPage;