import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import polioGraphic from '../img/COVID-TimelineHorizontal-01.png';
import COVIDGraphic from '../img/COVID-TimelineHorizontal-02.png';
import honeyB1 from '../img/HoneyBunsPullQuote.jpeg';
import honeyB2 from '../img/HoneyBunsThumbnail.jpeg';
import school from '../img/SchoolResegregation.jpeg';
import ship from '../img/ShipwreckMap.jpeg';



function GD1Page(props) {

    return(
        <div>
            <Hero title={props.title} />
            <Content> 
                <p> In the Spring 2021 at UNC-CH I was selected to be in the MEDIAHUB capstone course within UNC Hussman School of Journalism and Media. This course mocks a newsroom environment, grouping members of the course into cross-disciplinary teams of reporters, graphics, video and PR students. In the role of graphic designer for my group, I produced several data visualizations, editorial illustrations and infographics for their coinciding stories. Many of these graphics were published in print and online.</p>
                
         
                <h2 class= "sub-heading">Polio Vaccine Timeline</h2> 
                <p>I produced 2 timelines of the development of the polio vaccine compared to the COVID-19 vaccine to complement a story written by Maeve Sheehey. The story with the graphics were published in print and online in<a href="https://greensboro.com/news/state-and-regional/ncs-polio-city-was-a-nationally-known-medical-marvel-in-the-1940s-a-greensboro-boy/article_ed47bf7c-7770-11eb-9424-6b2c1e0a636d.html"> The Greensboro News and Record</a>,
                <a href="https://www.heraldsun.com/news/coronavirus/article250245650.html"> The Durham Herald-Sun</a> , and
                <a href="https://www.newsobserver.com/article250245650.html"> The Raleigh News and Observer</a>.
                </p>
                <div class= "main-div"><img class="building" src={polioGraphic} ></img></div>
                <div class= "main-div"><img class="building" src={COVIDGraphic} ></img></div>
                
                <h2 class= "sub-heading">Honey Bun Illustration</h2> 
                <p>I produced 2 illustrations for a story written by Maeve Sheehey about the local history of Honey Buns. The story with the graphics were published in print and online in <a href="https://greensboro.com/news/local/history/honey-buns-iconic-childhood-snack-traces-its-roots-to-greensboro/article_792ac654-95d7-11eb-bb95-670bbe86df5c.html">The Greensboro News and Record</a> ,
                <a href="https://chapelboro.com/town-square/honey-buns-n-c-history-with-a-sweet-sugary-glaze"> Chapelboro.com</a> ,
                <a href="https://www.thesnaponline.com/2021/03/15/state-honey-buns-n-c-history-with-a-sweet-sugary-glaze/"> The Triad City Beat</a> ,
                <a href="https://triad-city-beat.com/honey-buns/"> The Stanly News and Press</a> , and
                <a href="https://www.wral.com/honey-buns-n-c-history-with-a-sweet-sugary-glaze/19600638/"> WRAL What's on Tap</a>.
                </p>
                <div class= "main-div"><img class="building" src={honeyB1} ></img></div>
                <div class= "main-div"><img class="building" src={honeyB2} ></img></div>

                <h2 class= "sub-heading">NC School Resegregation Illustration</h2> 
                <p>I produced an editorial illustration for a story written by Marin Wolf about the resegregation of schools in North Carolina. The story with the graphic was published in <a href="https://indyweek.com/news/northcarolina/north-carolina-schools-are-re-segregating/">INDY Week</a>.
                </p>
                <div class= "main-div"><img class="building" src={school} ></img></div>

                <h2 class= "sub-heading">Outer Banks Infographic</h2> 
                <p>I produced an infographic of the Outer Banks of North Carolina to illustrate the setting for a story written by Maeve Sheehey. The story with the graphic was published in <a href="https://greensboro.com/news/state-and-regional/article_561d0e72-a46b-11eb-a333-136362111127.html">The Greensboro News and Record</a>.
                </p>
                <div class= "main-div"><img class="building" src={ship} ></img></div>
                </Content>

            
        </div>
    );

}
export default GD1Page;