import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import meBranding from '../img/meBranding.png';

import me1 from '../img/Branding.png';
import me2 from '../img/logocombo.png';
import mindmap from '../img/Mindmap.jpeg';
import stylescape from '../img/stylescape.png';

import type from '../img/typefaceMe.png';
import color from '../img/colorMe.png';
import cover from '../img/meBranding1.png';




function MercuriaPage(props) {

    return(
        <div class = "heroBig">
              <Content>
            <Hero title={props.title} />
          
            <div class="row">
          
         
          <div class="col-xs-12 col-sm-6">
          <div class="box">
              
            <img class= "img-fluid full-op" src = {meBranding}></img>
           
          
          </div>
        </div>
        
        <div class="col-xs-12 col-sm-6">
          <div class="description-sum">
              <ul class="summaryList">
              
                  <li><b>Role:</b></li>
                      <ul>
                          <li>Graphic Designer, Creative Direction</li>
                      </ul>
                  
                  <li><b>Project Goals:</b></li>
                      <ul>
                          <li>Create a cohesive brand identity </li>
                          <li>Design an original logo design that represents the brand's mission</li>
                          <li>Produce a 12-page comprehensive style guide that details logo and brand specifications</li>
                          
                      </ul>
                  
                  <li><b>Project Challenges:</b></li>
                      <ul>
                          <li>Representing the hypothetical brand based just on a creative brief </li>
                        
                      </ul>
         </ul>
       
          </div>
        </div>
     

</div>

<div class = "skillsBox">
       
    <p><b>Creative Brief: </b>(written by: Grayson Mendenhall) <em>Mercuria</em> is a environmentally-conscious athletic shoe brand that recycles ocean plastic into the material used to make its shoes. </p> 

    <p>Started by (fictional) tech-billionaire Milton Staples, Mercuria shoes are made with plastic harvested from the world’s oceans. Staples, a passionate environmentalist, is devoted to solving the problem of plastic pollution. </p>

<p><b>Objective: </b>
To create a brand identity that will let Mercuria stand against the other big-name competitors in the world of athletic shoes—Nike, Adidas, Reebok. It should portray a modern sensibility, with a nod to progressive thinking. The theme of environmentalism and protecting the natural world should be incorporated into the brand identity. It should feel somewhat high-tech and futuristic.</p>

<p><b>Target Audience: </b>
M/F ages 18-40. People who are athletic but also care about environmental issues and behaving ethically. People who like to go against the norm and try new things. People who love shoes of all kinds, but not necessarily for working out or playing sports. People who like and are intrigued by new technology.</p>

<p><b>Message:</b>
"Mercuria, protecting our planet with each step."</p>

<p><b>Tone Words: </b>
"Caring, Unique, Natural, Modern, Sleek, and Energetic"

</p>

   
       <p><b>Skills Used:</b>
                       <ul>
                        <li>Mind-mapping</li>
                           <li><b>Branding</b></li>
                           <li><b>Logo Design</b></li>
                           <li>Adobe Creative Suite Software</li> 

                       </ul></p>
      
       </div>
    
<h4 class= "sub-heading">Mind-map and Stylescape</h4>
<p>A "mind-map" organizes initial directions, buzzwords and ideas about a topic. I create mind-maps early on in the design process to construct a cohesive brand.</p>
<p>A "stylescape" is similar to a mood board in that it helps portray the visual language of a brand. A stylescape is created by first considering the various types of user personas that may embody the brand’s users.</p>

<div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-4">
      <div class="boxBlock">

      <img class= "img-fluid full-op" src = {mindmap}></img>
      <div class="figcap"><p>Mercuria mind-map</p></div>
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-8">
      <div class="boxBlock">
  
      <img class= "img-fluid full-op" src = {stylescape}></img>
      <div class="figcap"><p>Mercuria stylescape</p></div>
      </div>
    </div>

  </div>



<h4 class= "sub-heading">Logo</h4>
<p>I created an original logo for the brand and specified its usage in the style guide. The design features a wave-like "M" that represents the name of the company and its mission of reducing trash from the oceans. The abstract minimal symbol is also designed to be recognizable with competing athletic shoe brands such as Nike and Adidas.</p>

<div class="skillsBox"><img class= "img-fluid full-op"  src = {me2}></img></div>

<h4 class= "sub-heading">Typeface and Color</h4>
<div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="boxBlock">

      <div class="skillsBox"><img class= "img-fluid full-op" src = {type}></img></div>
      <div class="figcap"><p>Typeface specifications</p></div>
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="boxBlock">
  
      <div class="skillsBox"><img class= "img-fluid full-op" src = {color}></img></div>
      <div class="figcap"><p>Color specifications</p></div>
      </div>
    </div>

  </div>

  <div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-5">
      <div class="boxBlock">

      <div class="skillsBox"><img class= "img-fluid full-op" src = {me1}></img></div>
  
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-7">
      <div class="boxBlock">
  
      <h4 class= "sub-heading">Full 12-Page Style Guide</h4>
  

  <p>The full style guide package that includes the brand's mission, stylescape, logo with specifications, color palette, typography specs and use-case examples can be found <a href= "https://documentcloud.adobe.com/link/track?uri=urn:aaid:scds:US:8d857d3a-1d85-4f6e-adca-4727482f632c#pageNum=7"target="_blank" >here.</a></p>
  <div class="skillsBox"><img class= "img-fluid full-op" src = {cover}></img></div>
      </div>
    </div>

  </div>

    
  <div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 

            </Content>

            
        </div>
    );

}
export default MercuriaPage;