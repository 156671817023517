import React from 'react';
import Content from '../components/Content';

import Hero from '../components/Hero';
import {Link } from 'react-router-dom';
import {BsArrowRight } from 'react-icons/bs';



import CTTP from '../img/00homepageTitles-01.png';
import Persona from '../img/01IncarcerationTitles-01.png';
import Motion from '../img/02AiCardTitles-01.png';
import Mercuria from '../img/03MercuriaCardTitles-01.png';
import SXSW from '../img/04SXSWCardTitles-01.png';
import PFAS from '../img/05PFASCardTitles-01.png';
import Zine from '../img/06ChairsCardTitles-01.png';
import VR from '../img/VRTitles-01.png';

function HomePage(props) {

    return(

        <div class="container">
        <div class="needsPadding">
      <Hero  title={props.title} text= {props.text} subTitle={props.subTitle} />
     
      </div> 

        <div class="row">
          
          <div class="col-xs-12 col-sm-6">
            <div class="box boxAnimate">
            <Link to="/UX"><img class= "img-fluid" src = {CTTP}></img></Link>
             
            </div>
          </div>
         
          
          <div class="col-xs-12 col-sm-6">
            <div class="box boxAnimate">
            <Link to="/personas"><img class= "img-fluid" src = {Persona}></img></Link>
         
            </div>
          </div>
        </div>

        <div class="row">
          
          <div class="col-xs-12 col-sm-8">
            <div class="box ">
            <h2 class = "homepageh2">Check out my lastest work with UNC Chapel Hill's COVID-19 Testing Program.</h2>
            </div>
          </div>
         
          
          <div class="col-xs-12 col-sm-4">
            <div class="box boxAnimate">
            <Link to="/UX"><BsArrowRight className='left-arrow' ></BsArrowRight></Link>
            </div>
          </div>
        </div>


        
       
        <div class="row">
          
          <div class="col-xs-12 col-sm-4">
            <div class="box boxAnimate">
              <Link to= "/motiongraphics"><img class= "img-fluid" src = {Motion}></img></Link>
      
            </div>
            <div class="box boxAnimate">
          
              <Link to="/multimedia"><img class= "img-fluid" src = {SXSW}></img></Link>
            
            </div>
          </div>
          
          
          <div class="col-xs-12 col-sm-8">
            <div class="box boxAnimate">
            <Link to ="/VR"><img class= "img-fluid" src = {VR}></img></Link>
            </div>
          </div>
          
        </div>
      
  <div class="row">
    <div class="col-xs-12">
      <div class="box boxAnimate">
      <Link to ="/zine"><img class= "img-fluid" src = {Zine}></img></Link>
      </div>
    </div>
  </div>
  <div class="row">
          
          <div class="col-xs-12 col-sm-6">
            <div class="box boxAnimate">
            <Link to="/PFAS"><img class= "img-fluid" src = {PFAS}></img></Link>
             
            </div>
          </div>
         
          
          <div class="col-xs-12 col-sm-6">
            <div class="box boxAnimate">
            <Link to="/branding"><img class= "img-fluid" src = {Mercuria}></img></Link>
         
            </div>
          </div>
        </div>

        <div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 

        
</div>

    )

}
export default HomePage;

