import React from 'react';
import {Link } from 'react-router-dom';
import Hero from '../components/Hero';
import Content from '../components/Content';

import website from '../img/WebsiteFinal-Huggins.png';
import meBranding from '../img/meBranding.png';
import kioskFar from '../img/kioskFar.jpeg';
import current from '../img/CarolinaTogether.png';
import eight from '../img/mag8.png';




function ContactPage(props) {

    return(
        <div>
        
            <Content>
                    <Hero title={props.title} subTitle={props.subTitle} text={props.text} />
            
                    <h4 >Find me other places online</h4> 

                    <p>Thank you for checking out my portfolio. I'm currently searching for a position as a Associate UX Designer or Frontend Developer and would love to send over a copy of my official resume.</p>
                    <p> Email me at <a>nataliehugg@gmail.com</a>.</p>
                    <p>Or connect on <a href="https://www.linkedin.com/in/natalie-huggins/" target="_blank" rel="noopener noreferrer">LinkedIn</a>.</p>
        
        <div class="row">
          
          <div class="col-xs-12 col-sm-6">
            <div class="box boxAnimate">
            <Link to="/multimedia"><img class= "img-fluid" src = {website}></img></Link>
             
            </div>
          </div>
         
          
          <div class="col-xs-12 col-sm-6">
            <div class="box boxAnimate">
            <Link to="/branding"><img class= "img-fluid" src = {meBranding}></img></Link>
         
            </div>
          </div>
        </div>

      <div class="row">
          <div class="col-xs-12 col-sm-8">
            <div class="box boxAnimate">
            <Link to="/UX"><img class= "img-fluid" src = {current}></img></Link>
         
            </div>
          </div>

          <div class="col-xs-12 col-sm-4">
            <div class="box boxAnimate">
            <Link to="/motiongraphics"><img class= "img-fluid" src = {kioskFar}></img></Link>
         
            </div>
          </div>
        </div>

        <div class="row">
        <div class="col-xs-12">
        <div class="box boxAnimate">
        <Link to="/zine"><img class= "img-fluid" src = {eight}></img></Link>
        </div>
        </div>
        </div>
            
            
            
        <div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 
            
   
            </Content>
        </div>
    );

}
export default ContactPage;