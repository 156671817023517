import React from "react";
import { Redirect } from "react-router-dom"
import LoginComp from '../components/LoginComp';
import { useHistory } from "react-router-dom";

function LoginPage() {
  const history = useHistory();

  function handleClick(props) {
      if (LoginComp.user_password =='123'){
    (history.push("/work"))
  }
}


  return (
      <div class="login-container" >
      <LoginComp/>
    <button type="button" onClick={handleClick}>
      Enter Password
    </button>
    </div>
  );
}


export default LoginPage;