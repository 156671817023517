import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';

import zero from '../img/mag0.png';
import one from '../img/mag1.png';
import two from '../img/mag2.png';
import three from '../img/mag3.png';
import four from '../img/mag4.png';
import five from '../img/mag5.png';
import six from '../img/mag6.png';
import seven from '../img/mag7.png';
import eight from '../img/mag8.png';
import nine from '../img/mag9.png';


function ZinePage(props) {

    return(
        <div class= "heroBig">
                <Content> 
            <Hero title={props.title} />

            <div class="row">
          
         
          <div class="col-xs-12 col-sm-6">
          <div class="box">
              
            <img class= "img-fluid full-op" src = {zero}></img>
            
           
          
          </div>
        </div>

        <div class="col-xs-12 col-sm-6">
          <div class="description-sum">
              <ul class="summaryList">
              
                  <li><b>Role:</b></li>
                      <ul>
                          <li>Graphic Designer/Artist</li>
                      </ul>
                  
                  <li><b>Project Goals:</b></li>
                      <ul>
                          <li>Produce a 18-page zine</li>
                          <li>Include every story in English and Danish language</li>
                          <li>Be inspired by zine culture and artistic trends</li>
                          <li>Name and design title/logo/brand of zine</li>
                       
                      </ul>
                  
                  <li><b>Project Challenges:</b></li>
                      <ul>
                          <li>Incorporating both languages with equal visual hierarchy </li>
                        
                      </ul>
         </ul>

       
          </div>
         

        </div>
     

</div>

<div class = "skillsBox">

<p><b>Creative Brief: </b>Create an interactive 18-page multilingual digital zine about a topic of your choice. Include 1 centerpiece story and 5 single-story spreads. Incorporate 2 alternative story forms including a form of an infographic.</p>
       
   
       <p><b>Skills Used:</b>
                       <ul>
                           <li>Creative Direction</li>
                           <li>Layout Design</li>
                           <li>Infographic Design and Research</li>
                           <li><b>Magazine Design</b></li>
                           <li><b>Adobe Creative Suite Software</b></li> 

                       </ul></p>
      
       </div>
           
            <h4 class= "sub-heading">Zine Culture</h4>
            <p>A zine (pronounced “ZEEN”) is short for magazine. It is usually self-published original work by a small group or single person. Zines are commonly associated with activism for niche causes. They often teach readers about something or simply can be about authors' hobbies and/or interests.</p> 
            <h4 class= "sub-heading">Zine Topic</h4> 

                <p> I chose to produce a zine with a collection of articles about <b>danish chair design</b> and the influence of the <b>Bauhaus Movement.</b> This topic is a personal interest of mine, and I was excited to play around with untraditional magazine layouts that the zine culture encourages.</p>

                <p>I titled the zine 'BC YOU ASKED ME' to emphasize the culture of knowing a nerdy amount about an unusual topic and sharing that knowledge with anyone that makes the mistake of asking. I view this project not only as a representation of my skills in layout design but also my personal style. Some of the photos included in the zine I took in Copenhagen, while studying design and modern web programming there in the Spring of 2019. I took creative license with all article titles, though the text is not my own.</p>
                
               
                <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Back Cover (Below Left), Front Cover (Below Right)</p> 
      <div class="boxBlock">
      <img class= "img-fluid full-op" src = {one}></img>
   
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Introduction (Below Left), Contents (Below Right)</p> 
      <div class=" skillsBox boxBlock">
      <img class= "img-fluid full-op" src = {two}></img>
   
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Principles of Bauhaus Spread (Below)</p> 
      <div class=" skillsBox boxBlock">
      <img class= "img-fluid full-op" src = {three}></img>
   
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Bauhaus Colors and Shapes Spread (Below)</p> 
      <div class=" skillsBox boxBlock">
      <img class= "img-fluid full-op" src = {four}></img>
   
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Center Spread (Below), Bauhaus Inspired Danish Chair Design</p> 
      <div class=" skillsBox boxBlock">
      <img class= "img-fluid full-op" src = {five}></img>
   
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Center Spread Continued (Below), Bauhaus Inspired Danish Chair Design</p> 
      <div class=" skillsBox boxBlock">
      <img class= "img-fluid full-op" src = {seven}></img>
   
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Bauhaus Inspired Fashion Spread (Below)</p> 
      <div class=" skillsBox boxBlock">
      <img class= "img-fluid full-op" src = {six}></img>
   
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Bauhaus Movement Timeline (Below)</p> 
      <div class=" skillsBox boxBlock">
      <img class= "img-fluid full-op" src = {eight}></img>
   
      </div>
    </div>
    </div>

    <div class="row">
    
    <div class="col-xs-12 ">
    <p class="figCap">Most Famous Chairs of All Time (Below)</p> 
      <div class=" skillsBox boxBlock">
      <img class= "img-fluid full-op" src = {nine}></img>
   
      </div>
    </div>
    </div>

    <div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 
                
                </Content>

            
        </div>
    );

}
export default ZinePage;