import React from 'react';
import {Link } from 'react-router-dom';


function CardInfo(props) {



    return(
        <div className="g-card-info">
            <p className="g-card-title">{props.title}</p>
            <p className="g-card-sub-title">{props.subTitle}</p>
            <p className = "g-card-role">{props.role}</p>
            <a className="view #top" href><Link classname = "#top" to={props.link}>View Project</Link></a>
            
            {/* <a href={props.link} target="_blank" rel="noopener noreferrer">View</a> */}
        </div>
    );

}

export default CardInfo;