import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import appDisplay from '../img/persona-side.jpg';
import chart1 from '../img/chart1.png';
import chart2 from '../img/chart2.png';
import summary from '../img/personaSummary.png';
import mark from '../img/Personaposter_Huggins-01.png';
import journey from '../img/Personaposter_Huggins-02.png';
import wires from '../img/wireFrames-NCHealth.jpg';
import wires2 from '../img/wireFrame2.png';
import video2 from '../img/UXProject-Huggins.mp4';
import hierarchy from '../img/contentH.png';
import style from '../img/styleGuideNCHealth.png';
import memorials from '../img/memorials.png';
import memorials2 from '../img/memorials2.png'
import memorials3 from '../img/mem3.png'
import memorials4 from '../img/mem4.png'

function PersonasPage(props) {

    return(
        <div class= "heroSmall" >
        <Content>
        <Hero title ={props.title} />

        <div class="row">
      
     
        <div class="col-xs-12 col-sm-6">
        <div class="box">
          <img class= "img-fluid full-op" src = {appDisplay}></img>
         
        
        </div>
      </div>
      
      <div class="col-xs-12 col-sm-6">
        <div class="description-sum">
            <ul class="summaryList">
            
                <li><b>Role:</b></li>
                    <ul>
                        <li>UX Designer</li>
                    </ul>
                
                <li><b>Project Goals:</b></li>
                    <ul>

                        <li>Create a digital experience that houses the research, exploration and stories written about incarcerated North Carolinians who died during the COVID-19 crisis </li>
                        <li>Honor these individuals as people—not criminals—and tell their stories of incarceration while shining an investigative light on the criminal justice system in the United States</li>
                     
                    </ul>
                
                <li><b>Project Challenges:</b></li>
                    <ul>
                        <li>Creating a digital memorial that was most accessible and useful (in this case 'useful' being <em>meaningful</em>) to the target audience</li>
                        <li>Allow users to add their own memorial for a loved one that was not reported </li>
                       
                    </ul>
       </ul>
     
        </div>
      </div>
   


    </div>
    <div class = "skillsBox">
       
       <p><b>Digital Solution: </b> A digital memorial, designed to be a desktop website experience, that recognized the lives of the incarcerated North Carolinians that died from COVID-19 in prisons, and combined written stories with other digital elements. The web application would have a section where users could add memorial information for a loved one to be remembered. </p>
   
       <p><b>Skills Used:</b>
                       <ul>
                            <li><b>User Research</b></li>
                           <li><b>Persona Building/Journey Mapping</b></li>
                           <li>Prototyping and Wireframing</li>
                           <li>Medium Fidelity UI Mockups</li>
                           <li>Iterative Design</li>
                           <li>Collaboration with journalists</li>
                       </ul></p>
      
       </div>
    
     

            <p>A Community Journalism class in Hussman School of Journalism and Media was researching, exploring and writing stories about incarcerated North Carolinians who died during the COVID-19 crisis. They tasked my UX Design class, taught remotely by Laura Ruel in the Fall 2020 semester, with creating a digital experience that would present these written pieces and data from NC Health News with other digital elements.</p>

<p>To design the most effective experience, I needed to understand our audience for this project. I met virtually with Professor Erin Siegel Macentyre to get an overview of the project’s goals and began user research for the target audience.</p>

<h4 class= "sub-heading">Audience Research</h4>

<p>The primary audience for the experience was North Carolina natives that had relationship(s) with the memorialized victims, but the broader audience was North Carolina natives.  I broke down basic research of North Carolinanians by age, gender, education, ethnicity, access to the internet, income status and presence on social media measures. </p>

<div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="box">
      <img class= "img-fluid full-op" id="chart" src = {chart1}></img>
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="box">
      <img class= "img-fluid full-op" id="chart" src = {chart2}></img>
      
      </div>
    </div> 

    
  </div>
  <p>Then, I conducted <b>listening sessions</b> with community members about their perceptions of incarcerated COVID-19 victims and their memorialization. The sessions, lasting about 15 minutes each, were conducted over Zoom. I asked guiding questions and recorded individual's general knowledge of incarceration in the U.S., the issues surrounding the topic and the impact of COVID-19. These sessions combined with the research above helped shape possible <b>personas</b> of the audience.</p>

  <p>A “persona poster” represents a hypothetical individual, detailing their background, goals, frustrations and daily routines. </p> 

<p>Out of seven (7) possible personas developed, I was responsible for creating a poster and journey map for the persona archetype highlighted below, 'Modest Mark.' Other UX designers on my team created posters for the other personas.</p>
<div class="row">
    
    <div class="col-xs-12 col-md-6">
    <figcaption class="figcap" >Persona Poster</figcaption>
      <div class="box">
      <img class= "img-fluid full-op"  src = {mark}></img>
      </div>
    </div>
    
    <div class="col-xs-12 col-md-6">
    <figcaption class="figcap" >List of Personas</figcaption>
      <div class="box">
      
      <img class= "img-fluid full-op"  src = {summary}></img>
     
      
      </div>
    </div> 

    
  </div>



<p>A “journey map” maps how the persona might interact with the content. It includes what feelings it may elicit in them and what result(s) may ensue. These maps focus on the design question: </p>
<p><b><em> "How might this individual use/interact with a digital experience that honors the lives of incarcerated North Carolinians who died during COVID-19?"</em></b></p><p>

These maps also highlight potential "pain points," or places/functions/designs of the web application that might cause the persona user difficulty or confusion, that designers can anticipate and solve in lower fidelity mockups.
</p>
<h4 class= "sub-heading">Journey Map</h4>

<div class="row">
    
    <div class="col-xs-12 ">
      <div class="box">
      <img class= "img-fluid full-op" src = {journey}></img>
      </div>
    </div>
    </div>

  

    <div class="row">
    
    <div class="col-xs-12 col-md-6">
   
      <div class="boxBlock">
      <h4 class= "sub-heading">Content Hierarchy and Wireframing</h4>
      <p>After researching, I started designing the digital memorial's content flow and prototyping low fidelity wireframes. View annotated wireframes in Adobe XD <a href ="https://xd.adobe.com/view/73aa339c-7059-4711-400e-d64419d46cb2-059b/" target="_blank" >here.</a>  </p>
      </div>
    </div>
    
    <div class="col-xs-12 col-md-6">

      <div class="box">
      
      <img class= "img-fluid full-op"  src = {hierarchy}></img>
     
      
      </div>
    </div> 

    
  </div>
    
    
    <div class="row">
    
    <div class="col-xs-12  col-md-6">
      <div class="box">
      <img class= "img-fluid full-op" src = {wires}></img>
      </div>
    </div>
    


    <div class="col-xs-12  col-md-6">
      <div class="box">
      <img class= "img-fluid full-op" src = {wires2}></img>
      
      </div>
    </div> 

    
  </div>

  <h4 class= "sub-heading">Style and Colored Mockups</h4> 
  <p>The short video below showcases the medium fidelity prototype for the digital experience. I included static images of internal pages below as well. View all the colored comps in Adobe XD<a href ="https://xd.adobe.com/view/90ad4fcb-551b-4d62-6e8b-fbc802bb5aad-e69c/" target="_blank" > here. </a> </p>


           

  <div class="row">
    
    <div class="col-xs-12 col-md-6">
    
    <div class="figcap"><p>Style Guide</p></div>
      <div class="skillsBox">
      <img class= "img-fluid full-op"  src = {style}></img>
      </div>

      <div class="figcap"><p>About Page</p></div>
      <div class="box">
      <img class= "img-fluid full-op"  src = {memorials2}></img>
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6">
  
    <div class="figcap"><p>Medium Fidelity mockup flow (w/ intro animation)</p></div>
      <div class="box">
      <video controls className="img-fluid full-op" width= "100%"  name="XD Prototype" preload="self.scrollTo(0,0)">
        <source type="video/mp4"  src={video2}/>
    </video>

      
      </div>

      <div class="figcap"><p>Memorials Homepage</p></div>
      <div class="box">
      <img class= "img-fluid full-op"  src = {memorials}></img>
      </div>
      
      <div class="figcap"><p>Create a Memorial Page</p></div>
      <div class="skillsBox">
      <img class= "img-fluid full-op"  src = {memorials3}></img>
      </div>
    </div> 

    
  </div>

  <div class="row">
    
    <div class="col-xs-12 ">
   <p class= "figCap">Landing Page</p>
      <div class="box">
      <img class= "img-fluid full-op" src = {memorials4}></img>
      </div>
    </div>
    </div>



   <div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 

            </Content>

            
        </div>
    );

}
export default PersonasPage;