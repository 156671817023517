import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import {Link } from 'react-router-dom';
import appDisplay from '../img/introPicture1-CTTP.jpg';
import appDisplay2 from '../img/00homepageTile.jpg';
import persona from '../img/Persona1_Huggins.png';
import persona2 from '../img/Persona2_Huggins.png';
import testingapp from '../img/testing-app2.png';
import fullLayout from '../img/layout.png';

import newSetup from '../img/test-setup.png';
import newFlow from '../img/newFlow.png';

import current from '../img/home-ex.png';
import current2 from '../img/schedule-ex.png';
import single from '../img/singleShot.png';
import current3 from '../img/test-ex.png';



import overflow0 from '../img/Overflow0.jpeg';
import overflow1 from '../img/Overflow1.jpeg';
import wires from '../img/wireframes2.png';
import style from '../img/styleGuide-CTTP.png';
import Theirstyle from '../img/CarolinaStyleGuide.png';

import question from '../img/question.jpeg';
import logo from '../img/TogetherLogo.png';



function UXPage(props) {

    return(
        <div class= "heroBig" >
            <Content>
            <Hero title ={props.title} />

            <div class="row">
          
         
            <div class="col-xs-12 col-sm-6">
            <div class="box">
              <img class= "img-fluid full-op" src = {appDisplay}></img>
             
            
            </div>
          </div>
          
          <div class="col-xs-12 col-sm-6">
            <div class="description-sum">
                <ul class="summaryList">
                
                    <li><b>Role:</b></li>
                        <ul>
                            <li>Lead UX/UI Designer</li>
                        </ul>
                    
                    <li><b>Project Goals:</b></li>
                        <ul>
                            <li>Facilitate mandatory campus-wide COVID-19 testing on The University of North Carolina at Chapel Hill's campus for students and faculty</li>
                            <li>Reduce crowding and wait-time at campus testing centers</li>
                            <li>Promote safe practices to ensure a safe campus environment and experience with the return of in-person learning</li>
                        </ul>
                    
                    <li><b>Project Challenges:</b></li>
                        <ul>
                            <li>Time, the entire web app was built in 82 days over the university's 2020-2021 Winter Break </li>
                            <li>Creating a digital test-tracking method that did not produce crowding at testing sites, was digitally accessibile and easy to use </li>
                           
                        </ul>
           </ul>
         
            </div>
          </div>
       

 
        </div>
       <div class = "skillsBox">
       
        <p><b>Digital Solution: </b>Mobile app that tracked up-to-date tests for each user, including when next test was needed, allowing users to make test appointments through the app ahead of time to reduce lines on-site. The design included barcoded student information to make sign-in at testing sites more efficient as well. 
        <em> As of June 2021, the app helped UNC-CH's campus achieve a 0.3% COVID-19 positivity rate.</em></p>
    
        <p><b>Skills Used:</b>
                        <ul>
                            <li>Prototyping and Wireframing</li>
                            <li>User Testing</li>
                            <li>Iterative Design</li>
                            <li><b>High Fidelity User Interface Design</b></li>
                            <li><b>Cross-functional collaboration</b></li>
                        </ul></p>
       
        </div>
                 
        <h4 class= "sub-heading">What is the Carolina Together Testing Program?</h4>
        <p>The Carolina Together Testing Program is a university-wide program to manage COVID-19 on UNC-CH’s campus. The ongoing program was initiated in the Fall of 2020. At the beginning of the Spring semester (January 2021), the program launched HallPass, a mobile scheduling and test-tracking app.
 </p>
 <img class= "noPadding img-fluid full-op" src = {fullLayout}></img>
 <h4 class= "sub-heading">Defining the Problem</h4>
 <p>The program was ultimately designed to address the question:</p> <img class= "img-fluid full-op" src = {question}></img>
{/* <h4 class= "sub-heading">Early Problem-Solving</h4> */}

{/* <div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-6">
   
      <div class="box-block">
      <div class="figcap"><p>Fire Exit Map (below) credit Park Library</p></div>
      <img class= "img-fluid full-op" src = {firemap}></img>
     
      </div>

      <div class="box-block">
      <div class="figcap"><p>Entrance Only Door Sign (below) </p></div>
      <img class= "img-fluid full-op" src = {doorsign0}></img>
      
      </div>
      
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="box-block">
      <div class="figcap"><p>Campus Building Map (below) </p></div>
      <img class= "img-fluid full-op" src = {doorsign}></img>
      </div>
    </div>

  </div> */}
          
 {/* <div class="row">
    
    <div class="col-xs-12 ">
      <div class="box">
      <img class= "img-fluid full-op" src = {solution0}></img>
      </div>
    </div>
  </div> */}

{/* <p>Foot-traffic management was the first mechanism in the ideation process of finding a solution to the problem the program was facing. 
By first, limiting the number of students entering buildings, and designing essentially a one-way commute through classrooms, limiting face-to-face-contact and thus exposure to the virus, we address part of the question of how do we limit the exposure and spread of COVID-19.
</p>

  <div class="row">
    
    <div class="col-xs-12 ">
      <div class="box">
      <img class= "img-fluid full-op" src = {building}></img>
      </div>
    </div>
  </div>

  <h4 class= "sub-heading">The Need for Multiple Solutions</h4>
  <div class="box ">
  <img class= "img-fluid full-op borderStyle" src = {news}></img>
  </div> */}
<h4 class= "sub-heading">Background</h4>
<p>In-person classes at UNC were suspended only 2 days into the Fall semester and classes were moved entirely online. The need for a larger, more structured solution to manage COVID-19 on university campuses became exponentially more important. As the university set up on-campus COVID-19 testing stations, the team at Reese Innovation Lab developed the HallPass app to facilitate student and faculty testing.</p>
<p>With the start of the Spring semester, UNC instated new testing requirements for students attending classes on campus. Students were required to receive regular COVID-19 tests at the on-campus testing sites and maintain negative COVID-19 results to remain compliant.</p>
<br></br>
<h4 class= "sub-heading ">Test-tracking Mobile Application</h4>


{/* <h4 class= "sub-heading">Collaboration is Key</h4>
  <p>The team from Reese Innovation Lab is in collaboration with various departments in the university system and the UNC hospital system. </p> */}
  {/* <div class="row">
    
    <div class="col-xs-12 ">
      <div class="box">
      <img class= "img-fluid full-op" src = {solution1}></img>
      </div>
    </div>
  </div>

<p>Engineers in the lab were able to validate correct mask use and measure the distance between two users in the view of a camera using computer vision. They built this AI into vertical kiosks that displayed a live video feed of passing users, overlaid with messaging about their mask usage and social distance in real time.
The idea was to place these kiosks at entrances or high-traffic areas on campus to promote correct practices.
</p> */}



  {/* <div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="box">
      <img class= "img-fluid full-op" src = {kiosk0}></img>
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="box">
      <img class= "img-fluid full-op" src = {kioskFar}></img>
      </div>
    </div>

  </div>
  <h4 class= "sub-heading">Key UX Decisions</h4>
  <p><ul>
    <li>Clear disclaimer on each kiosk, stating that there was no video recording or face recognition data being collected from the cameras </li>
    <li>Minimal design of the boxes around people’s faces on-screen</li>
    <li>Positive messaging and encouragement of safe practices, not policing or monitoring behavior</li>
  </ul>
</p> */}


  {/* <h4 class= "sub-heading">Pitfalls and Prioritization</h4>
  <p>At the time, there was a lot of group discussion across the lab team, and consideration of the user interaction with the kiosks, but ultimately there was not enough emphasis on quantitative user testing methods.</p>
  <p>Part of this hole in the process was due to the prioritization of <em>solution 2</em>, which stakeholders in the university system were pressing harder for. </p> */}

  {/* <div class="row">
    
    <div class="col-xs-12 ">
      <div class="box">
      <img class= "img-fluid full-op" src = {solution2}></img>
      </div>
    </div>
  </div> */}
<div class ="row">
<div class="col-xs-12 col-md-7 noPadding">
  
<div class ="boxBlock larger noPadding">

<p><b>The HallPass app tracked student compliance statuses and notified student users when they needed to receive their next test to remain compliant. The web app was designed to be accessed on a mobile device when the user was visiting a COVID-19 testing site on-campus. Students could also use the app to schedule reservations before their arrival to decrease crowding at testing sites.</b></p>
    



  {/* <p>The question of <b>how do we limit the exposure and spread of COVID-19?</b> is answered with mandatory COVID-19 compliance statuses-- but we’re faced with the problem of <b>how do we facilitate students maintaining and understanding these mandatory requirements?</b></p> */}
  </div>
    </div>

    <div class="col-xs-12 col-md-5 noPadding2">
<div class ="box noPadding2">
<img class= "img-fluid-short full-op " src = {testingapp}></img>

    


  {/* <p>The university set up three on-campus testing pop-ups on campus for the Spring. New university guidelines made it mandatory for students returning to classes to receive weekly tests and keep up with their compliance.  
  </p> */}

  {/* <p>The question of <b>how do we limit the exposure and spread of COVID-19?</b> is answered with mandatory COVID-19 compliance statuses-- but we’re faced with the problem of <b>how do we facilitate students maintaining and understanding these mandatory requirements?</b></p> */}
  </div>
    </div>



    </div>

  <h4 class= "sub-heading">User Research and Early Prototyping</h4>
  <p>In order to design the best digital product, it was necessary to empathize with the primary group of users: undergraduate college students. While the HallPass app was open to use from faculty and staff, students attending courses during the Spring 2021 semester were obligated to follow the university's testing requirements. The broad goal of the program, important to keep in mind, being to keep campus safe at a time when the COVID-19 vaccine was not widely available yet but hybrid in-person learning was beginning to open up. </p>


  <div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="box">
      <p>After my research concluded that more than 90% of students on campus used a mobile device, the choice to move forward with a web application built for mobile interaction was a clear path to follow. The cross-functional team in the lab collaborated with medical and administrative professionals in the larger UNC system to build out the student experience of using the HallPass app when they were on-site at the COVID-19 testing pop-ups on campus.</p>
      </div>
      <div class="skillsBox">
      <img class= "img-fluid full-op" src = {persona}></img>
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-6">
    <div class="skillsBox">
      <img class= "img-fluid full-op" src = {persona2}></img>
      </div>
      <div class="box">
 
      <p>To further explore and document users' background, goals, frustrations and daily routines, I created various personas seen here. This exercise looks at the diverse reactions and experiences to the COVID-19 pandemic and the concept of regular testing requirements on college campuses different students would relate to.</p>
      </div>
      </div>

  </div>
<br></br>
  <h4 class= "sub-heading">User Needs</h4>
  <div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-6 noPadding">
      <div class="boxBlock noPadding">

<p>Identifying the user needs in the testing process helped prioritize the main functions of the HallPass app. Users needed:</p>
  <ul>
    <li>A visual status of compliance or non-compliance with testing requirements--this is where the app gets the name HallPass</li>
    <li>Date and time of deadline for next test needed to remain compliant</li>
    <li>(In the early userflow), Mechanism to scan the barcode from each test-tube on-site as a part of the testing process</li>
    <li>The ability to schedule testing appointments ahead of time to reduce lines</li>
  </ul>
  </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="boxBlock">

  {/* <div class="row">
    
    <div class="col-xs-12 ">
      <div class="box"> */}
     
      <img class= "img-fluid full-op" src = {overflow0}></img>
      <div class="figcap"><p>Early Userflow (on-site testing with app)</p></div>
      </div>
    </div>
  </div>
  
  <h4 class= "sub-heading">Wireframing Early Designs</h4>
  <div class="row">
    
    <div class="col-xs-12 ">
  
      <div class="box">
      <img class= "img-fluid full-op" src = {wires}></img>
      {/* <video controls className="img-fluid full-op skillsBox" width= "100%"  name="Motion Graphic" loading= "lazy" >
  <source type="video/mp4"  src={wires}/>
</video> */}
      </div>
    </div>
    
  </div>
  <h4 class= "sub-heading">User Testing</h4>
  <div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-6 noPadding">
      <div class="boxBlock noPadding">


<p>The team built out a low fidelity prototype of the app which included a scanning component that utilized smartphone cameras. The idea was that users would arrive on-site to complete their test, sign in to the HallPass app and scan a barcode on the test kit using the app to quickly link the test kit to their student identity. See 'Early Userflow' sketch above.</p>
<p>Upon testing this flow with users, the scanning component had technical issues. Manual entry of barcodes led to user-error and mismatched test kits to student users. The team made changes to the interaction design (see Userflow 2.0 sketch) to increase usability of the HallPass app and protect the effectiveness of the testing program.</p>
  </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="boxBlock">

  {/* <div class="row">
    
    <div class="col-xs-12 ">
      <div class="box"> */}
      <img class= "img-fluid full-op" src = {overflow1}></img>
      <div class="figcap"><p>Userflow 2.0</p></div>
      </div>
    </div>
  </div>



  {/* <div class="row">
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="box">
      <img class= "img-fluid full-op" src = {appflow}></img>
      </div>
    </div>
    
    <div class="col-xs-12 col-sm-6 col-md-6">
      <div class="boxBlock">
      <h4 class= "sub-heading">App Content Hierarchy</h4>
      <p>Next, I set out a flow chart of how users would navigate internal pages of the app. This had to include a registration process of users registering contact information and confirming their identity. The main user-needs were transcribed as the major functions of the app. The flow chart visualized flexible navigation between all functions. </p>
      </div>
    </div>

  </div> */}

  



 <h4 class= "sub-heading">Changes to the Flow</h4>

<p>Volunteer attendants were already staffed to monitor stations on-site and help students when necessary. The new flow took advantage of this. 
Instead of students scanning each test themselves via the app, attendants matched student id to test id through an admin process that was only accessible to them. The process included printing a corresponding label with the student’s id that the attendant would place on each test-tube upon arrival. With the new user flow, student identification was barcoded and displayed in place of its original list form. The attendant would scan the student’s barcode from their smartphone, then scan the barcode for the test kit. This process would print the label for the test-tube. Information was barcoded to make lines more efficient, as we had seen how much manual entry affected the overall time spent on-site.</p>

<div class="row">
    
    <div class="col-xs-12 col-md-8 ">
    <div class="boxBlock ">
    
    <img class= "img-fluid full-op skillsBox" src = {newFlow}></img>
    <div class="figcap"><p>New Interaction Flow with Attendant Scan (above)</p></div>

      </div>
    </div>
    
    <div class="col-xs-12 col-md-4">
      <div class="boxBlock">
      <div class="figcap"><p>Attendant Scanner Station</p></div>
      <img class= "img-fluid full-op" src = {newSetup}></img>
      
      </div>
    </div> 

    
  </div>
 

  <h4 class= "sub-heading">Execution</h4>
  <div class="boxBlock larger">
   
   <p>I was responsible for all iterations of low and high fidelity designs for the mobile app. Throughout the design process, I worked with the university <a href= "https://digitalaccessibility.unc.edu/" target="_blank" >Digital Accessibility Office </a>to ensure the experience and design elements were accessible to all. I gained a lot of knowledge about this sub-field in web design that I am very passionate about.</p>


     </div>
<div class="row">
    <div class="col-xs-12 ">
      <div class="boxBlock">
      <img class= "img-fluid full-op" src = {logo}></img>
      <div className="box text-box">
      <p>I built a dynamic design system using the university branding and style guide provided by the UNC Communications Department. I wanted the app to be cohesive to the Carolina Together brand built across campus.
</p></div>

<img class= "img-fluid full-op borderStyle" src = {Theirstyle}></img>
<div class="figcap"><p>The HallPass Design System was based on CTTP Branding</p></div>
   
      </div>
    </div> 
    </div>



  <h4 class= "sub-heading">Style and App Components</h4>
  
  <div class="row">
    
  <div class="col-xs-12 col-md-8 noPadding">
      <div class="boxBlock noPadding">
  

   
      <img class= "img-fluid full-op " src = {style}></img>
   

 {/* <div class="figcap"><p>My Testing page with statuses, final user flow (below) </p></div>
 <img class= "img-fluid full-op" src = {current}></img> */}




      </div>
    </div>
    
    <div class="col-xs-12 col-md-4">
      <div class="boxNoPadding">



      <img class= "img-fluid full-op noPadding3" src = {single}></img>
      {/* <video controls className="img-fluid full-op skillsBox" width= "100%"  name="Motion Graphic" loading= "lazy" >
  <source type="video/mp4"  src={hifid2}/>
</video> */}
      </div>
    </div> 

    
  </div>
<div className="row">
<ul class= "no-inline">
<li><b>Menus: </b> Minimalist icons represent the main functions of the app in the bottom navigation menu. This menu allowed users to easily access their testing information. Users can access the same menu in the hamburger menu in the upper right corner.</li>
<li> <b>Compliance: </b> Compliance statuses are color coded for fast visual recognition. Compliant is Green, Non-Compliant is Pink. The statuses are displayed on the testing page.</li>
     <li><b> Reservations: </b> The call-to-action to make a reservation on the app’s home page is highlighted with the green accent color in the final designs. 
Users can easily navigate to a weekly calendar schedule for their desired testing center to visit. Accordions allow users to view appointment times for each day at a time. </li>
<li><b>Last Test, Next Test:</b> The testing page tells users the most valuable information to keep a compliant status: the date of their last recorded test, and the date and time of when they need their next test.  </li> 
    <li> <b> Internal Documentation: </b> The app has a built-in testing-how-to to guide users through the user flow at the testing sites and using the app.</li>
     
     
      </ul>
</div>



<h4 class= "sub-heading">Final Design Tweaks </h4>


<div className="row">
<div class="col-xs-12 col-md-6 noPadding">



<p>The final design includes barcoded student identification on the home page of the app. This barcode is scanned upon arrival at the testing sites to link each test with the student taking the test. Once the two are linked virtually in the system, the student performs the nasal swab test on themselves and seals their test. The tests are transported to the lab where results are sent to students via the HallPass app and compliance statuses are updated. </p>
<p>The My Testing page emphasizes making reservations, rescheduling or cancelling existing reservations and viewing results. Users still see when they need their next test by, but the testing how-to guide is moved to the home page.</p>
</div>

<div class="col-xs-12 col-md-6">
<img class= "img-fluid full-op" src = {current}></img>
  </div>

  </div>

  <h4 class= "sub-heading">Reservation Flow </h4>
    {/* <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6">
    <div class="boxBlock">
   
    <h4 class= "sub-heading">Last Test, Next Test</h4>
    <p>The testing page tells users the most valuable information to keep a compliant status: the date of their last recorded test, and the date and time of when they need their next test.    </p>
    <h4 class= "sub-heading">Internal Documentation</h4>
      <p>The app has a built-in testing-how-to to guide users through the user flow at the testing sites and using the app.</p>
  <h4 class= "sub-heading">Reservations</h4>
 <p>The call-to-action to make a reservation on the app’s home page is highlighted with the green accent color in the final designs. 
Users can easily navigate to a weekly calendar schedule for their desired testing center to visit. Accordions allow users to view appointment times for each day at a time. 
</p>
<h4 class= "sub-heading">Scanner</h4>
<p>The earlier mockup (to the right) shows the design for the scanning process. </p>

      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6">
    <div class="box">
      <video controls className="img-fluid full-op skillsBox" width= "100%"  name="Motion Graphic" loading= "lazy" >
  <source type="video/mp4"  src={hifid}/>
</video>
      </div>
    </div> 

    
  </div> */}

 
{/* 
  <div class="row">
    
    <div class="col-xs-12">
      <div class="boxBlock">
      <div class="figcap"><p>UI testing page with scanner (below) </p></div>
      <img class= "img-fluid full-op" src = {vintage}></img>
      </div>
    </div>
    </div> */}



<img class= "img-fluid full-op" src = {current2}></img>

<h4 class= "sub-heading">Testing Statuses </h4>
<img class= "img-fluid full-op" src = {current3}></img>
    

    
 

  <h4 class= "sub-heading">Reflection and Results</h4>

  <p><b>Adapting is key:</b> Overall, I grew tremendously from this project. It was my first project designing for a large scope and involved coordination with many departments within the university system.
I faced a lot of doubt and imposter’s syndrome being not only the only designer on the project but the only student. I found my voice in meetings with cross-functional team members and worked through problems as they arose and after launch. The team never stopped trying to make the testing process as easy as possible for students on campus. I was inspired by senior advisors on the project that showed passion for the testing system and drive to provide students with safety measures during the pandemic.
After initial problems with the scanner, the program with HallPass received high ratings from students and faculty. </p> <p class = "larger largerP">There were little to no wait times at sites and no crowding. The campus had a 0.3% positivity rate of COVID-19 on campus by the end of the Spring 2021 semester. The university attributed such a low percentage to the HallPass app and Carolina Together Testing Program. </p>
  {/* <p>Other solutions to the problem of the campus’s safety in terms of in-person classes and COVID-19 worked in tandem with the mobile app and encouraged safe practices at the university. The mobile app solution was ultimately prioritized because it was designed for a user experience that involved on-site testing centers and weekly testing requirements. The app gave stakeholders quantitative data regarding the COVID-19 positivity rate on campus. 
</p>
<p>The app was designed and developed for launch in just 82 days, to be ready before the Spring semester. The app, used by the student and faculty body from January 2021 to May 2021, helped the university achieve a 0.3% positivity rate for COVID-19. 
 </p> */}


  <div class="row">
    
    <div class="col-xs-12 ">
      <div class="box">
      <img class= "img-fluid full-op" src = {appDisplay2}></img>
      </div>
    </div>
    
    
  </div>
  


 


 <div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 

 
            </Content>
            
        </div>
    );

}
export default UXPage;