import React from 'react';

import Hero from '../components/Hero';
import Content from '../components/Content';
import Carousel from '../components/Carousel';


function WorkPage(props) {

    return(
        
        <div>
           <Content>
            <Hero title={props.title} subTitle={props.subTitle} text={props.text} />
            </Content>
            
            <Carousel />
          
        </div>
       
    );

}
export default WorkPage;