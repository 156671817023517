import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';

import kioskFar from '../img/kioskFar.jpeg';
import kiosk0 from '../img/kiosk1.jpeg';
import kiosk from '../img/kioskIT1.jpg';
import kiosk2 from '../img/kioskIT2.jpg';
import kiosk3 from '../img/kioskHorizontal.png';
import ai from '../img/aiCover.png';
import news from '../img/newsKiosk.png';

import article from '../img/GreetHealth-2.png';
import video from '../img/GreetHealthfinal.mp4';


function MotionGPage(props) {

    return(
        <div class="heroBig">
             <Content>
            <Hero title={props.title} />

            <div class="row">
          
         
            <div class="col-xs-12 col-sm-6">
            <div class="box">
              <img class= "img-fluid full-op" src = {ai}></img>
             
            
            </div>
          </div>
          
          <div class="col-xs-12 col-sm-6">
            <div class="description-sum">
                <ul class="summaryList">
                
                    <li><b>Role:</b></li>
                        <ul>
                            <li>UX/UI Designer</li>
                        </ul>
                    
                    <li><b>Project Goals:</b></li>
                        <ul>
                            <li>Promote proper mask use and social distancing on The University of North Carolina at Chapel Hill's campus for students and faculty during the COVID-19 pandemic</li>
                            <li>Offer students and faculty returning to campus and or campus events a level of comfort/safety with <b>AI mask detecting kiosks</b></li>
                            
                        </ul>
                    
                    <li><b>Project Challenges:</b></li>
                        <ul>
                            <li>Creating a user experience and interface that promoted safe practices and <em>was NOT</em> perceived as surveillance</li>
                           
                        </ul>
                        <br></br>
                        <p>Photo Credit: Reese Innovation Lab</p>
           </ul>
    
            </div>
          </div>
       

 
        </div>
       <div class = "skillsBox">
       
        <p><b>Digital Solution: </b>Kiosks, designed to be placed at entrances of buildings or events, that use <b>computer vision</b> and <b>machine learning</b> to detect proper mask use and social distance of passing users. Clear graphics of individual mask use and distance of 6 feet or greater between users that encourage safe practices. The live video feed also displays a disclaimer that there is no facial recognition or recording of any kind. This message was important because user research highlighted the potential privacy concern with the kiosks. </p>
    
        <p><b>Skills Used:</b>
                        <ul>
                            <li>Iterative Design</li>
                            <li>High Fidelity UI Mockups</li> 
                            <li>Motion Graphics</li>
                            <li><b>User Experience Design for Artificial Intelligence </b></li>
                            <li><b>Press/Media Interviews</b></li>
                            <li><b>Collaboration with developers and university administrators</b></li>
                        </ul></p>
       
        </div>
           
           
            <p> <a href="https://www.greethealth.com/"target="_blank">Greet Health</a> is a culmination of the technologies developed in the UNC Reese Innovation Lab to facilitate safe in-person events during the COVID-19 pandemic. It's intended use is for corporate events at convention centers, but it uses the innovations developed during the Winter 2020 - Spring 2021 semesters for UNC-CH students and faculty returning to in-person learning. </p>   

            <p>I want to highlight the user experience and interface design of the Health Greeter Kiosks. <b>These kiosks were piloted at Fall 2020 UNC Football games in Kenan Memorial Stadium and displayed in some buildings on campus to encourage mask-use as students returned to in-person classes in the Spring 2021 semester.</b> </p>
            <h4 class= "sub-heading">UX for AI Mask Detection</h4>
            <p>Designing the experience of a kiosk involved much research and discussion. We needed to present the technology of the kiosks as safety measures that would put users at ease rather than alarm them with issues of personal privacy. I, along with one other designer, designed many iterations of the graphics that overlayed the live video feed of the kiosks. The graphics included a 'Correct Mask Use' status and a 'Mask Needed status' for passing users. The interface design was rooted in the goal of encouragement and engagement. </p>

            <p>I describe the UX process and perspective in this interview with BCC Research. It's worth the listen!</p>

            <iframe src="https://open.spotify.com/embed/episode/5xfKYMjzxsZK5sezoHYIlo" width="100%" height="232" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            
            <div class="row">
    
    <div class="col-xs-12 col-md-6">
   
      <div class="boxBlock">
      <h4 class= "sub-heading">UI Iterations</h4>
      <p>I designed many iterations of UI mockups as the function of the kiosks developed during the project.</p> 
      <p>In the beginning of the process, the team believed that the kiosks would be useful to report data of mask usage and social distance on campus. There were even ideas of gamifying the kiosks to encourage mask use with ideas discussed in the podcast above. </p>
      <p>The team decided to shift from those ideas and focus on clear messaging that promoted mask use and assured users that no facial recognition data or video recording was transmitted. </p>
      <div class="figcap"><p>AI Kiosk in Carroll Hall (Full Kiosk)</p></div>
      <img class= "img-fluid full-op"  src = {kioskFar}></img>
      <div class="figcap"><p>Images taken by my during Spring 2021 semester.</p></div>
   
         </div>

    
    </div>
    
    <div class="col-xs-12 col-md-6">

      <div class="boxBlock">
      <div class="figcap"><p>Early Designs</p></div>
      <img class= "img-fluid full-op"  src = {kiosk}></img>
      

      <img class= "img-fluid full-op"  src = {kiosk2}></img>
      <div class="figcap"><p>AI Kiosk in Carroll Hall (Up Close)</p></div>
      <img class= "img-fluid full-op"  src = {kiosk0}></img>
      
     
      
      </div>
    </div> 

    
  </div>
           

            <h4 class= "sub-heading">Media Coverage</h4>
            
            <p> Along with coverage from <a href="https://open.spotify.com/episode/5xfKYMjzxsZK5sezoHYIlo?si=ataYQJPySKi7t4nkdytUzQ" target="_blank" rel="noopener noreferrer">BCC Research</a> the kiosks were covered by other news outlets when they were piloted at football games and as a part of the Greet Health launch for corporate events. </p> 
            

            <div class="row">
    
    <div class="col-xs-12  col-md-7">
      <div class="box">
      <a href= "https://www.wral.com/coronavirus/kiosks-help-stop-spread-of-coronavirus-at-unc-ch-football-games/19349181/"target="_blank"><img class= "img-fluid full-op"  src = {news}></img></a>
      </div>
    </div>
    


    <div class="col-xs-12  col-md-5">
      <div class="boxBlock">
      <iframe class="img-fluid full-op add-padding" width="560" height="315" src="https://www.youtube.com/embed/6-uJePdj8d4" target="_blank" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <iframe class="img-fluid full-op add-padding" width="560" height="315" src="https://www.youtube.com/embed/udAJbgSVams" target="_blank" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <p class="adds-padding">WRAL covers the story <a href="https://www.wral.com/coronavirus/kiosks-help-stop-spread-of-coronavirus-at-unc-ch-football-games/19349181/" target="_blank">here. </a> </p>
      
<p>A more in depth <a href="https://medium.com/reese-innovate/how-greet-health-technologies-helped-unc-stabilize-covid-19-b07d5ee55822" target="_blank" >Medium </a>article describes the Greet Health Package well. </p>

<p><a href="https://www.dailytarheel.com/article/2021/03/university-health-greeter-kiosks" target="_blank" >The Daily Tar Heel</a> also covered the kiosks.</p>
      </div>
    </div> 

    
  </div>

  
            


<h4 class= "sub-heading">Motion Graphic/Animation</h4>
<p>When Greet Health launched, I was on the team that wrote the script and produced the motion graphics for this promotional and informational content. I gained experience using Adobe After Effects for this project. The video was published on LinkedIn and other UNC Reese Innovate channels. <b>Press PLAY to view.</b></p>


<video controls className="img-fluid full-op skillsBox" width= "100%"  name="Motion Graphic" loading= "lazy" >
  <source type="video/mp4"  src={video}/>
</video>




<div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 
   

</Content>
            
        </div>
    );

}
export default MotionGPage;