import React from 'react';
import Hero from '../components/Hero';
import Content from '../components/Content';
import audio from '../img/Final-final-audio-MEJO565.mp3';
import water from '../img/water.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function PFASPage(props) {

    return(
        <div class="heroBig">
             <Content> 
            <Hero title={props.title} />

            <div class="row">
          
         
          <div class="col-xs-12 col-sm-6">
          <div class="box">
              
            <img class= "img-fluid full-op" src = {water}></img>
            
           
          
          </div>
        </div>
        
        <div class="col-xs-12 col-sm-6">
          <div class="description-sum">
              <ul class="summaryList">
              
                  <li><b>Role:</b></li>
                      <ul>
                          <li>Writer, Reporter, Producer</li>
                      </ul>
                  
                  <li><b>Project Goals:</b></li>
                      <ul>
                          <li>Investigate heavy metal contamination in well water</li>
                          <li>Produce an audio, podcast form story</li>
                      </ul>
                  
                  <li><b>Project Challenges:</b></li>
                      <ul>
                          <li>Write an <em>engaging</em> narrative about chemicals in drinking water</li>
                          <li>Translate explanations from experts in their field and scientists for an average listener/reader</li>
                        
                      </ul>
         </ul>

       
          </div>
         

        </div>
     

</div>


<div class = "skillsBox">

<p><b>Digital Story:</b> An investigative story in audio form about the dangers of PFAS- a group of harmful human-made chemicals- in drinking water. Research and contacts were built during a semester in <em>Environmental Storytelling</em> taught by Kate Sheppard. </p>
       
   
       <p><b>Skills Used:</b>
                       <ul>
                           <li>Remote Interviews</li>
                           <li>Journalistic writing</li>
                           <li>Fact-Checking</li>
                           <li><b>Audio Story production</b></li> 

                       </ul></p>
      
       </div>
           
            
         
            <h4 class= "sub-heading">The Story</h4>
            <p>This project involved months of reporting and writing as a part of a course in UNC Hussman School of Media and Journalism that was focused on PFAS. My research partner Maggie Mayone and I interviewed multiple professionals in the UNC School of Global Public Health. All of the reporting and collaboration was accomplished remotely over Zoom.</p><p>The story transcript can be found <a href= "https://www.pfasinnc.com/post/well-water-testing-nc" target="_blank" >here</a>. </p>
            <h4 class= "sub-heading">Listen Here</h4>
            
            <div class="skillsBox">
                <audio controls  width= "100%"  name="PFAS AudioStory" loading= "lazy" >
  <source type="audio/mp3"  src={audio}/>
</audio></div>

<div class= "backTop"> <a href="#top">BACK TO TOP OF PAGE</a></div> 

           
            </Content>

            
        </div>
    );

}
export default PFASPage;