
import React from 'react';
import { useHistory } from "react-router-dom";



class LoginComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      islogged: false,
      loginParams: {
      user_password: ""
      }
    };
  }

  
  render() {
    return (
      <div className= "login-container">
        <form onSubmit={this.login} className="form-signin">
          <h1 className="h3 mb-3 font-weight-normal">Please Enter Password</h1>
          <div className="row">
            <div className="col">
 
              <input
                type="password"
                name="user_password"
                onChange={this.handleFormChange}
                placeholder="Enter Password"
              />
              <input type="submit" value="Login" />
            </div>
          </div>
          <p>user_password === "123"</p>
        </form>
      </div>
    );
  }
}

export default LoginComp;