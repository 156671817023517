import React from 'react';

import ScrollToTop from 'react-router-scroll-top'
import {BrowserRouter as Router,Route,Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import './App.css';


import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import Login from './pages/Login';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import WorkPage from './pages/WorkPage';
import UXPage from './pages/UXPage';
import SXSWPage from './pages/SXSWPage';
import PFASPage from './pages/PFASPage';
import PersonasPage from './pages/PersonasPage';
import MotionGPage from './pages/MotionGPage';
import ZinePage from './pages/ZinePage';
import MercuriaPage from './pages/MercuriaPage';
import GD1Page from './pages/GD1Page';
import VRPage from './pages/VRPage';



class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: 'Natalie Huggins',
      headerLinks: [
        { title: 'Home', path: '/' },
        { title: 'Login', path: '/login' },
        { title: 'About', path: '/about' },
        { title: 'Work', path: '/work'},
        { title: 'Contact', path: '/contact' }, 
        { title: 'UX', path:'/UX'}, 
        { title: 'Multimedia', path:'/multimedia'}, 
        { title: 'Motion', path:'/motiongraphics'}, 
        { title: 'Brand', path:'/branding'},
        { title: 'Storytelling', path:'/PFAS'}, 
        { title: 'GD1', path:'/illustrations'}, 
        { title: 'GD2', path:'/zine'}, 
        { title: 'Personas', path:'/personas'}
      ], 
      home: {
        title: 'It\'\s so good to finally meet you.',
        subTitle: 'I\'\m Natalie, UX/UI Designer, Developer, Digital Storyteller.',
        text: ' '
      },
      login:{
        title:'test'
      },

      about: {
        title: 'About Me'
      },
      work: {
        title: 'Work'
      },
      contact: {
        title: 'Let\'\s Collab'
      }, 
      ux: {
        title: 'Carolina Together Testing Program'
      }, 
      multimedia: {
        title: 'South-By-SouthWest Music Festival Redesign'
      }, 
      motiongraphics: {
        title: 'Health Greeter AI Kiosks'
      }, 
      branding: {
        title: 'Brand Identity and Logo Design'
      }, 
      PFAS:{
        title: 'Digital Storytelling'
      }, 
      illustrations:{
        title: 'Published Graphics'
      },
      zine: {
        title: 'Bilingual Magazine Design'
      }, 
      personas: {
        title: 'Memorializing Incarcerated COVID-19 Victims'
      }, 
      VR:{
        title: 'Lenovo Marketing VR Demo'
      }
    }
  }
  


  render() {
    
    
    return (

      
      <Router>
         <ScrollToTop/>
        <Container className="p-0" fluid={true}>
          <Navbar className="border-bottom" bg="transparent" expand="sm">
            <Navbar.Brand className = "top-name"><Link className="nav-link" to="/">Natalie Huggins</Link></Navbar.Brand>
            <Navbar.Toggle className="border-0" aria-controls="navbar-toggle" />
            <Navbar.Collapse id="navbar-toggle">
              <Nav className = "ml-auto">
              
            
                <Link className="nav-link linkNav" to="/work">Work</Link>
                <Link className="nav-link linkNav" to="/about">About</Link>
                <Link className="nav-link linkNav" to="/contact">Keep in Touch</Link>
              
              </Nav>
            </Navbar.Collapse>
          </Navbar>
         
          <Route path="/" exact render={() => <HomePage title={this.state.home.title} subTitle={this.state.home.subTitle} text={this.state.home.text} />} />
          <Route path="/login" exact render={() => <Login title={this.state.login.title}/>} />
          <Route path="/about" render={() => <AboutPage title={this.state.about.title} />} />
          <Route path="/work" render={() => <WorkPage title={this.state.work.title} />} />


    
          <Route path="/contact" render={() => <ContactPage title={this.state.contact.title} />} />
          <Route path="/UX" render={() => <UXPage title={this.state.ux.title} />} />
          <Route path="/multimedia" render={() => <SXSWPage title={this.state.multimedia.title} />} />
          <Route path="/motiongraphics" render={() => <MotionGPage title={this.state.motiongraphics.title} />} />
          <Route path="/branding" render={() => <MercuriaPage title={this.state.branding.title} />} />
          <Route path="/VR" render={() => <VRPage title={this.state.VR.title} />} />
          <Route path="/PFAS" render={() => <PFASPage title={this.state.PFAS.title} />} />
          <Route path="/illustrations" render={() => <GD1Page title={this.state.illustrations.title} />} />
          <Route path="/zine" render={() => <ZinePage title={this.state.zine.title} />} />
          <Route path="/personas" render={() => <PersonasPage title={this.state.personas.title} />} />
        

          <Footer> </Footer>
        </Container>
      </Router>


    );
   
  }
}


export default App;

